export default function () {
  const contactForm = document.querySelector('.contact-form');

  if (contactForm) {
    contactForm.addEventListener('submit', function (e) {
      const elementsToValidate = [
        this.querySelector('[name="form-name"]'),
        this.querySelector('[name="form-email"]'),
        this.querySelector('[name="form-message"]')
      ];

      elementsToValidate.forEach((elem) => {
        if (elem.value.length < 2 || elem.value.length > 10000) {
          elem.classList.add('invalid-field');
        } else {
          elem.classList.remove('invalid-field');
        }
      });

      if (contactForm.querySelectorAll('.invalid-field').length > 0) {
        contactForm.classList.add('invalid-form');
        e.preventDefault();
      } else {
        contactForm.classList.remove('invalid-form');
      }
    });
  }
}
