export default function () {
  const tabs = document.querySelectorAll('.tabs');

  tabs.forEach((tab) => {
    const buttons = tab.querySelectorAll('nav button');

    buttons.forEach((button, buttonIndex) => {
      button.addEventListener('click', function () {
        if (!button.classList.contains('active')) {
          const index = buttonIndex;
          buttons.forEach((button) => button.classList.remove('active'));
          button.classList.add('active');

          tab
            .querySelector('.tabs-content li.active')
            .classList.remove('active');
          tab
            .querySelectorAll('.tabs-content li')
            [index].classList.add('active');
        }
      });
    });
  });
}
