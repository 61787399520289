export default function () {
  const rwdMenuTrigger = document.querySelector('.rwd-main-nav-trigger');

  if (rwdMenuTrigger) {
    rwdMenuTrigger.addEventListener('click', function () {
      if (window.innerWidth < 1280) {
        document.body.classList.add('show-menu');
      }
    });

    const rwdMenuClose = document.querySelector('.close-menu');

    rwdMenuClose.addEventListener('click', function () {
      document.body.classList.remove('show-menu');
    });

    var specifiedElement = document.querySelector('#main-nav .wrapper');

    function outsideAction(event) {
      if (event.target === document.querySelector('.rwd-main-nav-trigger'))
        return;

      var isClickInside = specifiedElement.contains(event.target);

      if (!isClickInside) {
        document.body.classList.remove('show-menu');
      }
    }

    document.addEventListener('click', function (event) {
      if (window.innerWidth < 1280) {
        outsideAction(event);
      }
    });

    document.addEventListener('touchstart', function (event) {
      if (window.innerWidth < 1280) {
        outsideAction(event);
      }
    });
  }
}
