export default function () {
  if ('IntersectionObserver' in window) {
    const header = document.querySelector('#header');
    const banner = document.querySelector('#banner');

    if (banner) {
      new IntersectionObserver(
        function (entries) {
          //if (window.innerWidth >= 1280) {
          for (var _i = 0; _i < entries.length; _i++) {
            if (entries[_i].isIntersecting) {
              header.classList.add('transparent');
            } else {
              header.classList.remove('transparent');
            }
          }
          //}
        },
        {
          rootMargin: '-94px 0px 0px 0px'
        }
      ).observe(banner);
    }
  }
}
