import LazyLoad from 'vanilla-lazyload';

import observer from './modules/observer';
import tabs from './modules/tabs';
import rwdMenu from './modules/rwd-menu';
import swiper from './modules/swiper';
import formValidation from './modules/form-validation';
import consentBar from './modules/consent-bar';
import { readConsentCookie } from './modules/consent-manager';

window.initConsent = readConsentCookie;

document.addEventListener('DOMContentLoaded', function () {
  window.lazyLoadInstance = new LazyLoad({
    elements_selector: '.lazyload',
    unobserve_entered: true
  });

  observer();
  swiper();
  tabs();
  rwdMenu();
  formValidation();
  consentBar();

  const banner = document.querySelector('#banner');

  if (banner) {
    window.addEventListener('scroll', function () {
      var scrollPosition = window.pageYOffset;
      var bgParallax = document.getElementsByClassName('parallax')[0];
      var limit = bgParallax.offsetTop + bgParallax.offsetHeight;
      if (scrollPosition > bgParallax.offsetTop && scrollPosition <= limit) {
        bgParallax.style.backgroundPositionY =
          50 - (10 * scrollPosition) / limit + '%';
      } else {
        bgParallax.style.backgroundPositionY = '50%';
      }
    });
  }

  const contactForm = document.querySelector('#contact-form');
  const contactFormButton = document.querySelector('#sendButton');
  if (contactForm) {

    var formCallback = function () {
      contactForm.submit();
    };

    contactFormButton.addEventListener("click", function () {

      var personValue = document.querySelector('#form-email').value
      var validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(personValue)
      personValue = personValue.replace(/\s/g, '');
      if (validEmail) {
        gtag('set', 'user_data', { "email": personValue });
        console.debug('email parsed', personValue)
      }
      else {


        if (!personValue.startsWith('+48'))
          personValue = '+48' + personValue;
        gtag('set', 'user_data', { "phone_number": personValue });
        console.debug('phone parsed', personValue)
      }

      gtag('event', 'conversion', { 'send_to': 'AW-1068521468/Q96cCP3J4YwZEPyvwf0D', 'event_callback': formCallback });
    })


  }

  window.gtag_report_conversion = function (url) {
    var callback = function () {
      if (typeof (url) != 'undefined') {
        window.location = url;
      }
    };
    gtag('event', 'conversion', {
      'send_to': 'AW-1068521468/MiVECIDK4YwZEPyvwf0D',
      'event_callback': callback
    });
    return false;
  }


  // document.addEventListener('click', function (e) {
  //   const target = e.target;
  //   const callpageElems = ['cp-tooltip__inner', 'cp-widget-button'];

  //   console.log(target);

  //   if (
  //     Array.from(target.classList).some((item) => callpageElems.includes(item))
  //   ) {
  //     console.log('Callpage callback');
  //   }
  // });
});
