import Cookies from 'js-cookie';
import { fire } from 'delegated-events';

const COOKIE_PRIVACY_NAME = 'gs_privacy_preferences';

//document.addEventListener('consent:mandatory', function (e) {
//    console.debug('mandatory fired, with valuue',e.detail);
//});

document.addEventListener('consent_change', function () {
  var updateItem = {
    ad_storage: window.consent_table['marketing'] ? 'granted' : 'denied',
    ad_user_data: window.consent_table['marketing'] ? 'granted' : 'denied',
    ad_personalization: window.consent_table['marketing'] ? 'granted' : 'denied',
    analytics_storage: window.consent_table['analytics'] ? 'granted' : 'denied',
    functionality_storage: window.consent_table['mandatory'] ? 'granted' : 'denied',
    security_storage: window.consent_table['mandatory'] ? 'granted' : 'denied'
  }

  console.log('Constent update GA4 Item', updateItem);

  gtag('consent', 'update', updateItem);
});

export function refreshConsentTable(consents) {
  if (!consents) return;

  window.consent_table = [];

  consents.forEach(consent => {
    window.consent_table[consent] = true;
  });

  window.consent_table['mandatory'] = true;
  fire(document, 'consent_change');
}

export function storeConsentCookie(consentsList) {
  Cookies.set(COOKIE_PRIVACY_NAME, JSON.stringify(consentsList), { expires: 365 });

  refreshConsentTable(consentsList);
}

export function readConsentCookie() {
  const currentPreferences = Cookies.get(COOKIE_PRIVACY_NAME);

  if (!currentPreferences) return null;

  const parsedPreferences = JSON.parse(currentPreferences);

  refreshConsentTable(parsedPreferences);

  return parsedPreferences;
}