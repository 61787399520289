import Swiper, { Navigation, Lazy, Pagination } from 'swiper';

Swiper.use([Navigation, Lazy, Pagination]);

export default function () {
  window.addEventListener('load', () => {
    const mainPageTestimonials = new Swiper(
      '#testimonials-slider > .swiper-container',
      {
        navigation: {
          nextEl: '.testimonial-next',
          prevEl: '.testimonial-prev'
        },
        pagination: {
          el: '.testimonial-pagination',
          clickable: true
        },
        loop: true,
        threshold: 15,
        breakpoints: {
          600: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1100: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        },
        on: {
          slideChange: function () {
            window.lazyLoadInstance.update();
          }
        }
      }
    );

    const mainPagePortfolio = new Swiper(
      '.portfolio-snapshots-slider .swiper-container',
      {
        navigation: {
          nextEl: '.swiper-button-next.portfolio-button',
          prevEl: '.swiper-button-prev.portfolio-button'
        },
        // lazy: {
        //   enabled: true,
        //   loadedClass: 'img-loaded'
        // },
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          630: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20
          },
          1280: {
            slidesPerView: 5,
            spaceBetween: 20
          },
          1681: {
            slidesPerView: 6,
            spaceBetween: 20
          }
        }
      }
    );
  });
}
