import { on } from 'delegated-events';

import privacySettings from './consent-settings.json'
import {
  storeConsentCookie
} from './consent-manager';

const initObserver = (sentinel, onFunction, offFunction = null) => {
  if ('IntersectionObserver' in window) {
    let observer = null;

    observer = new IntersectionObserver(
      function (entries) {
        for (var i = 0; i < entries.length; i++) {
          if (entries[i].isIntersecting) {
            if (offFunction) offFunction();
          } else {
            onFunction();
            if (!offFunction) observer.unobserve(sentinel);
          }
        }
      }
    );

    observer.observe(sentinel);
  }
}

export default function () {
  if (!privacySettings)
    return;

  const {
    links, description, consents, buttonPosition
  } = privacySettings;
  const privacyBox = document.createElement('div');

  privacyBox.classList.add('privacy-box');
  buttonPosition === "left" ? document.body.classList.add('left-side-cookies') : document.body.classList.add('right-side-cookies');

  const logoUrl = '/assets/images/logo.svg';
  const consentsHtml = consents && consents.length > 0 ? `
            <ul class="privacy-box__consents">
                ${consents.map(consent => {
    if (consent.name === '') return;

    return `<li>
                <div class="consent-detail ${consent.description.length > 0 ? "toggleable" : ""}">
                    <div class="consent-detail__header">
                        <strong class="${consent.mandatory ? "default" : ""}">${consent.name}</strong>

                        ${consent.mandatory ? "<span>wymagane</span>" : ""}

                        <label for="${consent.key}" class="consent-detail__checker ${consent.mandatory ? "mandatory" : ""}">
                            <input id=${consent.key} type="checkbox" ${consent.mandatory ? 'checked' : ''} />
                            <div></div>
                        </label>
                    </div>

                    ${consent.description.length > 0 ? `
                        <div class="consent-detail__description"><p>${consent.description}</p></div>
                    ` : ""}
                </div>
            </li>`;
  }).join('')}
            </ul>` : "";

  const linksHtml = links && links.length > 0 ? `
            <ul class="privacy-box__links">
                ${links.map(link => {
    return `<li><a href="${link.link}">${link.anchor}</a></li>`;
  }).join('')}
            </ul>
        ` : "";

  privacyBox.innerHTML = `
        <div class="privacy-box__header">
            ${logoUrl.length > 0 ? `<img src="${logoUrl}" alt="" />` : ""}
            <strong>Zarządzaj zgodami plików cookie</strong>
        </div>
        <div class="privacy-box__wrapper">
            ${description.length > 0 ? `<p class="privacy-box__description">${description}</p>` : ""}
            ${consentsHtml}
        </div>
        <div class="privacy-box__actions">
            <button class="privacy-reject-all">Odmów</button>
            <button class="privacy-preferences">Preferencje</button>
            <button class="privacy-accept-all">Akceptuj wszystkie</button>
        </div>
        ${linksHtml}
    `;

  function hidePrivacyBox() {
    privacyBox.remove();
    document.body.classList.remove('privacy-box-visible');
    showPrivacyBoxTrigger();
  }

  const showPrivacyBox = () => {
    document.body.appendChild(privacyBox);
    document.body.classList.add('privacy-box-visible');
  }

  const showPrivacyBoxTrigger = () => {
    const privacyBoxTrigger = document.querySelector('.privacy-box-trigger');

    if (privacyBoxTrigger) {
      document.querySelector('.privacy-box-trigger').classList.remove('hide');

      privacyBoxTrigger.addEventListener('click', function () {
        showPrivacyBox();
      });
    }
  }

  if (window.consent_table) {
    for (const consent in window.consent_table) {
      const checker = privacyBox.querySelector(`input[type="checkbox"]#${consent}`);

      if (checker) {
        checker.checked = true;
      }
    }

    showPrivacyBoxTrigger();
  } else {
    // COOKIE OBSERVER
    const cookieSentinel = document.querySelector('.privacy-sentinel');

    initObserver(cookieSentinel, function () {
      setTimeout(function () {
        showPrivacyBox();
      }, 100);
    });
  }

  const saveUserPreferences = () => {
    const list = [...privacyBox.querySelectorAll('input[type="checkbox"]:checked')].map(consent => consent.getAttribute('id'))

    storeConsentCookie(list);
    hidePrivacyBox();
  }

  on('click', '.privacy-box .toggleable .consent-detail__header > strong', function (e) {
    this.parentElement.parentElement.classList.toggle('show');
  });

  on('click', '.privacy-box__actions button', function () {
    const buttonType = this.classList[0];

    switch (buttonType) {
      case 'privacy-reject-all':
        [...privacyBox.querySelectorAll('input[type="checkbox"]')].filter(consent => consent.getAttribute('id') !== 'mandatory').forEach(consent => consent.checked = false);
        saveUserPreferences();
        break;

      case 'privacy-preferences':
        if (privacyBox.classList.contains('preferences-visible')) {
          saveUserPreferences();
        }

        break;

      case 'privacy-accept-all':
        [...privacyBox.querySelectorAll('input[type="checkbox"]')].forEach(consent => consent.checked = true);
        saveUserPreferences();
        break;

      default:
        break;
    }
  });

  on('click', '.privacy-box:not(.preferences-visible) .privacy-preferences', function () {
    privacyBox.classList.add('preferences-visible');
    this.textContent = 'Zapisz preferencje';
  });

}
